import React from "react"
import Fade from 'react-reveal/Fade';
import "./style.scss"

const Testimonial = ({ text, image, name, position }) => {
  return (
    <div className="testimonial">
      <div className="container">
        <Fade bottom duration={1000}>
          <p className="title testimonial__text">{text}</p>
        </Fade>
        <Fade bottom duration={1000}>
          <div className="testimonial__box">
            <div className="testimonial__img">
              <img src={image} alt="" />
            </div>
            <div className="testimonial__info">
              <span className="testimonial__name">{name}</span>
              <span className="testimonial__position">{position}</span>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  )
}

export default Testimonial
