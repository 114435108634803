import React from "react"
import Fade from 'react-reveal/Fade'
import "./style.scss"

const JobDetails = () => {
  return (
    <div className="job-details">
      <div className="container">
        <Fade duration={1000} bottom>
          <span className="caption job-details__caption">Job Details</span>
        </Fade>
        <div className="job-details__wrap">
          <Fade duration={1000} bottom>
            <span className="title job-details__title">Your responsibilities</span>
          </Fade>
          <ul className="job-details__list">
            <Fade duration={1000} bottom>
              <li>Work under the direction of a Project Manager, on various projects.</li>
            </Fade>
            <Fade duration={1000} bottom>
              <li>Solicit business requirements from various stakeholders</li>
            </Fade>
            <Fade duration={1000} bottom>
              <li>Develop BRDs and FRDs</li>
            </Fade>
          </ul>
        </div>
        <div className="job-details__wrap">
          <Fade duration={1000} bottom>
            <span className="title job-details__title">Qualifications</span>
          </Fade>
          <ul className="job-details__list">
            <Fade duration={1000} bottom>
              <li>Deep understanding of information systems</li>
            </Fade>
            <Fade duration={1000} bottom>
              <li>Highly adaptive and excellent communicator</li>
            </Fade>
            <Fade duration={1000} bottom>
              <li>Experienced agile Project Management methodologies</li>
            </Fade>
            <Fade duration={1000} bottom>
              <li>Knowledge of organizational Change Management concepts and business process improvement methods</li>
            </Fade>
            <Fade duration={1000} bottom>
              <li>Experienced with developing BRDs, FRDs and User Stories</li>
            </Fade>
            <Fade duration={1000} bottom>
              <li>Proven ability to quickly earn the trust of sponsors and stakeholders</li>
            </Fade>
            <Fade duration={1000} bottom>
              <li>Deliver tough messages with grace</li>
            </Fade>
            <Fade duration={1000} bottom>
              <li>Execute with limited information and ambiguity</li>
            </Fade>
            <Fade duration={1000} bottom>
              <li>Experienced working with remote teams</li>
            </Fade>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default JobDetails
