import React from "react"
import Link from "gatsby-link"
import Fade from "react-reveal/Fade"
import "./style.scss"

const ReadyToApply = () => {
	return (
		<div className="ready-to-apply">
			<div className="container">
				<Fade duration={1000} bottom>
					<span className="title ready-to-apply__title">Ready to apply?</span>
				</Fade>
				<Fade duration={1000} bottom>
					<Link className="btn btn--black" to="/apply">
						Apply Now
          			</Link>
				</Fade>
			</div>
		</div>
	)
}

export default ReadyToApply
