import React, { useRef, useState, useEffect } from "react"
import Fade from "react-reveal/Fade"
import Link from "gatsby-link"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ReadyToApply from "../components/ReadyToApply"
import HaveQuestions from "../components/HaveQuestions"
import JobDetails from "../components/JobDetails"
import WhyCider from "../components/WhyCider"
import Testimonial from "../components/Testimonial"
import Loader from "../components/Loader"

import Heading from "../components/Heading"
import img from "../images/Erin.png"

import "../styles/pages-style/job.scss"

const Job = () => {
	const jobDetailsBlock = useRef(null)
	const [offsetJobDetails, setOffsetJobDetails] = useState(0)
	const [bgChangeJobDetails, setBgChangeJobDetails] = useState(false)

	const whyCiderBlock = useRef(null)
	const [offsetWhyCider, setOffsetWhyCider] = useState(0)
	const [bgChangeWhyCider, setBgChangeWhyCider] = useState(false)

	const handleScroll = (e) => {
		let offsetTop = window.pageYOffset
		let windowHeight = window.innerHeight

		offsetTop + (windowHeight / 2) > offsetJobDetails ? setBgChangeJobDetails(true) : setBgChangeJobDetails(false)
		offsetTop + (windowHeight / 2) > offsetWhyCider ? setBgChangeWhyCider(true) : setBgChangeWhyCider(false)
	}

	useEffect(() => {
		let bodyRect = document.body.getBoundingClientRect()

		let jobDetailsRect = jobDetailsBlock.current.getBoundingClientRect()
		let jobDetailsPos = jobDetailsRect.top - bodyRect.top
		setOffsetJobDetails(jobDetailsPos)

		let whyCiderRect = whyCiderBlock.current.getBoundingClientRect()
		let whyCiderPos = whyCiderRect.top - bodyRect.top
		setOffsetWhyCider(whyCiderPos)

		window.addEventListener('scroll', handleScroll)

		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
		
	}, [offsetJobDetails, offsetWhyCider, handleScroll])

	const [animate, setAnimate] = useState(true)

	function changeLoad() {
		setTimeout(() => {
			setAnimate(false)
		}, 500)
	}

	useEffect(() => {
		window.addEventListener('load', changeLoad(), false)
	}, [])

	return (
		<div>
			{!animate ? null : <Loader />}
			<Layout>
				<SEO title="Services | Cider Consulting" />
				<Heading
					caption="Business Analyst"
					text="This is a client facing role, interactions with various client personnel."
					bottomContent={
						<div className="job-hero">
							<div className="job-hero__wrap">
								<Fade bottom duration={1000}>
									<p className="job-hero__text">We are a remote-first company located in Mahwah NJ, this will grant you the privilege of working remotely 2-3 days/week</p>
								</Fade>
								<Fade bottom duration={1000}>
									<Link className="btn" to="/apply">Apply Now</Link>
								</Fade>
							</div>
						</div>
					}
				/>
				<div ref={jobDetailsBlock} className={bgChangeJobDetails ? 'change-bg-job-details' : ''}>
					<JobDetails />
				</div>
				<div>
					<ReadyToApply />
				</div>
				<div ref={whyCiderBlock} className={bgChangeWhyCider ? 'change-bg-why-cider' : ''}>
					<WhyCider />
				</div>
				<HaveQuestions />
			</Layout>
		</div>
	)
}

export default Job

export const query = graphql`
  query JobPageQuery {
    site {
      siteMetadata {
        description
      }
    }
    Erin: file(name: { eq: "Erin" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    team: file(name: { eq: "team" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    careers2: file(name: { eq: "careers2" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`